import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Trbm from "@tightrope/trbm"
import CtaDisclosure from "@tightrope/ctadisclosure"
import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"


const prdbestData = import("./data/data.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
    body {
      background-image: url(/img/laptop-dark.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
      background-position-x: center;
    }

    .trbm-module--trbm h1 {
      font-weight: bold;
    }

    .trbm-module--trbm h2 {
      color: #333;
    }

    .trbm-module--trbm .trbm-module--bullets li {
      font-weight: lighter;
    }

    .trbm-module--trbm .trbm-module--bullets {
        list-style: disc;
        width: 66%;
    }

    .footerlinks-module--br--2T_Dp,
     .footerlinks-module--br--2T_Dp a{
      color: rgba(255,255,255,0.6);
    }

    .trbm_cta {
        padding: 15px 100px;
        border: none;
        font-size: 35px;
        color: #fff;
        font-weight: bold;
        background: #43ba19;
        border-radius: 10px;
        cursor: pointer;
        transition: .2s ease;
    }

    .footerlinks-module--br, .footerlinks-module--br a {
      color: #f1f1f1;
    }

    `}
    </style>

    <title>Free PDF Editor - pdftab.com</title></Helmet>
    <section>
      <Trbm data={prdbestData}>
        <CtaDisclosure></CtaDisclosure>
      </Trbm>
      </section>
    </HomepageLayout>
  )
}
